exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aviso-de-privacidad-jsx": () => import("./../../../src/pages/aviso-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-jsx" */),
  "component---src-pages-como-invertir-espana-jsx": () => import("./../../../src/pages/como-invertir-espana.jsx" /* webpackChunkName: "component---src-pages-como-invertir-espana-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-golden-visa-jsx": () => import("./../../../src/pages/golden-visa.jsx" /* webpackChunkName: "component---src-pages-golden-visa-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-registro-19-mayo-22-jsx": () => import("./../../../src/pages/registro-19mayo22.jsx" /* webpackChunkName: "component---src-pages-registro-19-mayo-22-jsx" */)
}

